/* eslint-disable react/prop-types */
import React from 'react'

import { graphql } from 'gatsby'

import HomepageBanner from '../components/sections/_banner/home'
import Portfolio from '../components/sections/portfolio'
import Partners from '../components/sections/partners'
import CaseStudies from '../components/sections/caseStudies'
import Clients from '../components/sections/clients'
import Layout from '../layouts/default'
import Services from '../components/sections/services'
import Testimonials from '../components/sections/testimonials'
import Achievements from '../components/sections/achievements'

function HomepageTemplate({
  data: {
    wpPage: {
      template: { pageHome: acf },
      seo,
    },
  },
}) {
  const simpleText = {
    description: acf?.simpleTextDescriptionBloc2,
    title: acf?.simpleTextTitleBloc2,
  }
  return (
    <Layout seo={seo}>
      <HomepageBanner data={acf} />
      <Portfolio data={acf} />
      {parseInt(acf.displayServices, 10) === 1 && acf.services && (
        <Services services={acf.services} />
      )}
      {parseInt(acf.displayRealizations, 10) === 1 && acf.realizations && (
        <Achievements achievements={acf.realizations} />
      )}
      {parseInt(acf.displayServices2, 10) === 1 && acf.servicesBlock2 && (
        <Services services={acf.servicesBlock2} />
      )}
      {acf.useCase && (
        <CaseStudies cases={acf.useCase} simpleText={simpleText} />
      )}
      {acf.clients && <Clients clients={acf.clients} />}
      <Partners data={acf} />
      {acf.testimonials && (
        <Testimonials
          testimonials={acf.testimonials}
          title={acf.testimonialsBlockTitle}
        />
      )}
    </Layout>
  )
}

export default HomepageTemplate

export const query = graphql`
  query HomePage($id: String!) {
    wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_HomePage {
          pageHome {
            headerPrimaryButton {
              title
              url
            }
            headerImageRight {
              title
              sourceUrl
            }
            headerImageFile {
              sourceUrl
            }
            services {
              ...serviceFragment
            }
            headerDescription
            headerImageLeft {
              title
              sourceUrl
            }
            headerImageMobile {
              title
              sourceUrl
            }
            headerSecondaryButton {
              title
              url
            }
            headerTitle
            leftRightBlockCta {
              title
              url
            }
            leftRightBlockDescription
            leftRightBlockImageFile {
              title
              sourceUrl
            }
            leftRightBlockImageFormat
            leftRightBlockMediaType
            leftRightBlockTitle
            leftRightBlockVideoFile {
              title
              sourceUrl
            }
            useCase {
              title
              url
              imageFile {
                sourceUrl
                title
              }
            }
            clients {
              ...clientFragment
            }
            displayRealizations
            displayServices
            displayServices2
            offers {
              description
              url
              title
              imageFile {
                sourceUrl
              }
            }
            realizations {
              ...realizationFragment
            }
            servicesBlock2 {
              ...serviceFragment
            }
            simpleTextCta {
              title
              url
            }
            simpleTextCtaBloc2 {
              title
              url
            }
            simpleTextDescription
            simpleTextDescriptionBloc2
            simpleTextTitle
            simpleTextTitleBloc2
            testimonials {
              ...testimonialFragment
            }
            testimonialsBlockTitle
          }
        }
      }
      ...yoastMeta
    }
  }
`
