/* eslint-disable react/prop-types */
import React from 'react'

import SimpleText from '../simpleText'
import PortfolioSlider from './slider'

import './styles.scss'

export default ({ data }) => (
  <div className="portfolio">
    <SimpleText
      className="heading"
      title={data.simpleTextTitle}
      description={data.simpleTextDescription}
      button={data.simpleTextCta}
    />
    <PortfolioSlider pictures={data.offers} imgClass="portfolio" />
  </div>
)
